import React from "react"
import { Link } from "gatsby"

export default props => {
  return (
    <div class="flex flex-wrap -mx-1 lg:-mx-4">
      {props.data.edges.map(({ node }) => (
      <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 overflow-hidden rounded-lg shadow-lg text-gray-600">
        <Link to={'/' + node.productName.split(" ").join("-").toLowerCase()}><img class="rounded-full h-32" src={node.productImage} alt={node.productName} /></Link>
        {node.productName}
    </div>
        ))}
     </div>
  )
}
