import React from "react";
import HeaterList from "../components/heater-list"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero"

export default function IndexPage({ data, pageContext }) {
  return (
    <Layout>
    
      <SEO
        title="Stay warm outside in your garden"
        keywords={[`patio heaters`, `garden heaters`, `outdoor heaters`]}
        description="Looking to find the best patio heater? We explain everything you need to know, and list the best deals for you to buy."
      />

<Hero heading='Enjoy a warmer garden' subheading='Socialise and dine in a warm garden this winter'/>

<div className="flex flex-wrap w-full container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
<div className="p-6">
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">Don't get stuck inside this winter!</h3>
<p className="text-gray-600 mb-8">During the summer our gardens and patios become an extension of our homes, socialising and dining alfresco.
So when the weather turns colder why stop? Pop up a gazebo to keep the rain away and keep yourself warm and cosy with a patio heater.</p>
<h2 className="text-3xl text-gray-800 font-bold leading-none mb-3">The best patio heaters.</h2>
<HeaterList data={data.products} />
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">Find the perfect heater for you.</h3>
<p className="text-gray-600 mb-8">During the summer our gardens and patios become an extension of our homes, socialising and dining alfresco.
So when the weather turns colder why stop? Pop up a gazebo to keep the rain away and keep yourself warm and cosy with a patio heater.</p>
<h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">Fuel type</h3>
<p className="text-gray-600 mb-8">There are two main fuel options, gas and electric. And while there are variances within each, the key differentors are to do with efficiency, convenience and output.</p>
<h4 className="text-2xl text-gray-800 font-bold leading-none mb-3">Gas heaters</h4>
<p className="text-gray-600 mb-8">Gas as a fuel is quick to heat</p>
</div>
</div>

    </Layout>
  );
}

export const query = graphql`
  query {
    products: allGoogleSheetProductsRow {
      edges {
        node {
          productImage
          productName
        }
      }
    }
  }
`